
import { Component, Vue } from 'vue-property-decorator'
import { PlantInfo } from '../../types/plantEstimation'
import PlantEstimationActual from './Actual.vue'
@Component({
  components: { PlantEstimationActual }
})
export default class List extends Vue {
  private searchInfo: { projectName: string; engineeringName: string; engineeringNum: string} = {
    projectName: '',
    engineeringName: '',
    engineeringNum: ''
  }

  private loading = false
  private tableData: Array<PlantInfo> = []
  private page = 1
  private size = 10
  private total = 0

  private showDialog = false
  private estimatedId = ''
  created () {
    this.getList()
  }

  getList () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<PlantInfo> }>(this.$apis.plantEstimated.selectPlantEstimatedByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.loading = false
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 查询
  onSearch () {
    this.page = 1
    this.getList()
  }

  // 新增
  addEstimated () {
    this.$router.push({ name: 'plantEstimationAdd' })
  }

  // 字典
  managePlant () {
    this.$router.push({ name: 'plantEstimationPlantDic' })
  }

  // 详情
  onDetail (id: string) {
    this.$router.push({
      name: 'plantEstimationDetail',
      params: { id: id }
    })
  }

  // 录入实种量
  onImport (id: string) {
    this.showDialog = true
    this.estimatedId = id
  }

  // 更新实种量
  updateInfo () {
    this.showDialog = false
    this.getList()
  }

  // 编辑
  onEdit (id: string) {
    this.$router.push({
      name: 'plantEstimationEdit',
      params: { id: id }
    })
  }

  // 导出
  onExport (id: string) {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.plantEstimated.exportEstimatedData, { estimatedId: id }).finally(() => {
      loading.close()
    })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.plantEstimated.deletePlantEstimated,
        {
          estimatedId: id
        }).then(() => {
        this.getList()
        this.$message.success('删除成功')
      })
    }).catch((err) => {
      console.log(err)
    })
  }
}
